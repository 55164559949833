<script setup lang="ts">
const { currentWebsite } = useWebsite()
</script>

<template>
  <div class="md:py-xl py-lg lg:py-4xl bg-background-secondary grid-standard">
    <div
      class="col-span-12"
      :class="{
        'col-span-4 col-start-5 md:col-span-2 md:col-start-6':
          currentWebsite === 'ea7' || currentWebsite === 'armani-clubs',
      }"
    >
      <component
        :is="megaLogoMap[currentWebsite]"
        class="text-icon-primary h-full w-full"
        :class="currentWebsite === 'ea7' ? 'max-h-[180px]' : ''"
        aria-hidden="true"
      />
    </div>
  </div>
</template>
