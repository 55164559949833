<script setup lang="ts">
import type { HeaderMonoGramProps } from './HeaderMonoGram.props'
const localePath = useLocalePath()

const { currentWebsite } = useWebsite()
const isCurrentWebsite = (siteTo: string) =>
  siteTo.split('/')[2] === currentWebsite.value

defineProps<HeaderMonoGramProps>()
</script>

<template>
  <div
    id="header-monogram"
    class="header-monogram bg-primitives-off-white transition-margin positive-padding flex h-[var(--monogram-strip-height)] w-full items-center justify-between py-[14px] duration-[500ms] ease-in-out"
    :class="{
      hideMonogram: isHidden,
    }"
  >
    <NuxtLink
      :to="localePath('/')"
      :aria-label="`${$ts('accessibility.goTo')} ${$ts('accessibility.sites.global')}`"
    >
      <DefaultIconsGlobalMenuLogo aria-hidden="true" class="h-5 w-[84px]" />
    </NuxtLink>
    <div class="sub-home flex items-center justify-center">
      <NuxtLink
        v-for="site in subHome"
        :key="`site-${site.icon}`"
        :to="site.to"
        :aria-label="`${$ts('accessibility.goTo')} ${$ts(`accessibility.sites.${site.icon}`)}`"
        class="group mr-6 last:mr-0 md:mr-7"
        :aria-current="isCurrentWebsite(site.to) ? 'page' : undefined"
      >
        <component
          :is="menuLogoMap[site.icon as MenuLogoType]"
          class="h-7 w-7"
          :class="{
            'opacity-20 duration-300 hover:opacity-50 group-focus-within:opacity-50':
              !isCurrentWebsite(site.to),
          }"
          aria-hidden="true"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hideMonogram {
  margin-top: calc(-1 * var(--monogram-strip-height)); /* move it up */
  opacity: 0;
}
</style>
